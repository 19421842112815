import {bindable, BindingEngine, customElement, inject, LogManager, observable} from 'aurelia-framework';
import {EventAggregator} from 'aurelia-event-aggregator';
import {DialogService} from 'aurelia-dialog';
import {I18N} from "aurelia-i18n";
import * as Promise from "bluebird";
import {Client} from '../../api/client';
import {ConditionMatcher} from "../../condition-builder/condition-matcher";
import {UniversalEntitySelect} from "../../dialog/universal-entity-select";
import {ProductChoice} from "../product-choice";
import {ConfigurationLoader} from "../../form/loader/configuration-loader";
import {FlashService} from "../../flash/flash-service";
import {StandardActions} from "../../action/standard-actions";
import {FlightDialog} from "../../dialog/flight-dialog";
import {ModuleConfigClient} from "../../api/module-config-client";
import * as _ from "lodash";
import moment from "moment";
import {InsuranceApiChoice} from "../insurance-api-choice";
import {ColumnsGenerator} from './cart-view/columns/columns-generator.js';
import {ExecutorRegistryFactory} from "./action-executor/executor-registry-factory";
import {BucketsGenerator} from "./buckets-generator";
import '../../array/array-move';
import {FlightSearch} from "../../flight/flight-search";
import {TicketSearch} from "../../deutsche-bahn/ticket-search";
import printJS from 'print-js';

import "./cart.less";
import {CostsDialog} from "../../costs/costs-dialog";
import {AccommodationSearch} from "../../offer/accommodation-search";
import {ServiceSearch} from "../../offer/service-search";
import {LocaleService} from "../../i18n/locale-service";
import {TransferSearch} from "../../offer/transfer-search";
import {FromDateBucketsGenerator} from "./from-date-buckets-generator";
import {ChargesDialog} from "./charges/charges-dialog";

const logger = LogManager.getLogger("OrderTourismCart");

@customElement('sio-order-tourism-cart')
@inject(
    Client, ConditionMatcher, EventAggregator, BindingEngine, DialogService,
    ConfigurationLoader, FlashService, StandardActions, ModuleConfigClient, I18N,
    ColumnsGenerator,
    ExecutorRegistryFactory,
    BucketsGenerator,
    FromDateBucketsGenerator,
    LocaleService
)
export class OrderTourismCart {
    client;
    conditionMatcher;
    ea;
    bindingEngine;
    dialogService;
    formConfigLoader;
    flash;
    actions;
    i18n;

    @bindable order;
    @bindable allItemsChecked;
    @observable selectedParticipants;
    @observable selectedItems;

    selectAllCheckbox;
    subscriptions;
    itemsObserver;
    sortable;
    addActions = [];
    loading;
    productColumn;
    participantColumn;

    buckets = [];
    items;
    participants;
    journeys;
    journeyTitle;

    participantsEnabled = false;

    addEmptyParticipantAction = {
        actionClass: 'btn btn-default btn-sm',
        label: 'TN',
        popupHint: 'Teilnehmer hinzufügen',
    };

    addParticipantAction = {
        actionClass: 'btn btn-default btn-sm',
        label: 'TN (K)',
        popupHint: 'Kunde als TN hinzufügen',
    };

    purchasePriceAction = {
        actionClass: 'btn btn-link btn-xs pull-right purchase-price-action',
        icon: 'inbox',
        popupHint: 'Berechnung anzeigen'
    };

    calculatedPurchasePriceAction = {
        actionClass: 'btn btn-link btn-xs pull-right purchase-price-action',
        icon: 'inbox',
        popupHint: 'Zusammensetzung anzeigen'
    };

    openChargesContentConfig = {
        actionClass: 'btn btn-link btn-xs pull-right purchase-price-action font-weight-bold',
        icon: 'glyphicon glyphicon-piggy-bank',
        popupHint: 'Zuschläge / Rabatte anzeigen'
    };

    defaultSortableOptions = {
        filter: '.not-sortable',
        //Important so that indexes are correct
        draggable: '.draggable',
        handle: '.sio-icon-menu',
        onMove: event => !event.related.classList.contains('not-sortable')
    };

    defaultWithoutParticipant = {
        'participants': [],
        'sortableOptions': Object.assign(
            {},
            this.defaultSortableOptions,
            {
                group: {
                    name: 'without-participant',
                    put: function (e) {
                        logger.debug("Try to add item to without-participant group", e);

                        return true; // Allow moving any item from any group to "without-participant" group
                    }
                },
            }
        ),
    };

    constructor(client, conditionMatcher, ea, bindingEngine, dialogService, formConfigLoader,
                flash, actions, moduleConfigClient, i18n, columnsGenerator,
                executorRegistryFactory, bucketsGenerator, fromDateBucketsGenerator, localeService) {
        this.client = client;
        this.conditionMatcher = conditionMatcher;
        this.ea = ea;
        this.bindingEngine = bindingEngine;
        this.dialogService = dialogService;
        this.formConfigLoader = formConfigLoader;
        this.flash = flash;
        this.actions = actions;
        this.moduleConfigClient = moduleConfigClient;
        this.i18n = i18n;
        this.columnsGenerator = columnsGenerator;
        this.executorRegistry = executorRegistryFactory.createRegistry();
        this.bucketsGenerator = bucketsGenerator;
        this.fromDateBucketsGenerator = fromDateBucketsGenerator;
        this.localeService = localeService;

        this.productProviders = {};
        this.journeys = new Map();
        this.participants = new Map();
        this.items = new Map();
        this.withoutParticipant = Object.assign({'items': []}, this.defaultWithoutParticipant);

        this.editItem = this.editItem.bind(this);

        this.client.get('order/product-provider', 60).then(modules => {

            modules = _.orderBy(modules, ['priority'], ['desc']);

            for (const module of modules) {

                if (!module.label || module.hide || module.key == 'order/product-prototype') {
                    continue;
                }

                this.addActions.push({
                    actionClass: 'btn btn-sm btn-default',
                    label: module.label,
                    module: module
                });

                this.productProviders[module.key] = module;
            }
        });

        this.client.get('order/product-prototype?sort[0][0]=shortName&sort[0][1]=ASC', 10).then(data => {
            this.productPrototypes = data.items;
            this.productPrototypeActions = [];

            for (const prototype of this.productPrototypes) {
                this.productPrototypeActions.push({
                    buttonClass: 'btn btn-default btn-sm',
                    label: prototype.shortName,
                    actionCallback: () => {

                        let data = {
                            participants: this.selectedParticipants,
                            product: {
                                id: prototype.id,
                                modelId: prototype.modelId
                            },
                            provider: 'order/product-prototype',
                        };

                        return this.client
                            .patch('order/order-item-add/trigger/' + this.order.id, data)
                            .then(() => this.reloadOrder());
                    }
                });
            }
        });
    }

    initializeToolbars(toolbars) {
        const menu = toolbars;
        let newMenu = [];

        for (const toolbar of menu) {

            if (toolbar.label != 'order.actions') {
                continue;
            }

            for (const action of toolbar.actions) {
                action.buttonClass = action.buttonClass || 'btn btn-default btn-sm';
                action.showLabel = !action.icon;

                if (action.hasOwnProperty('workflowId')) {
                    action.type = 'workflow';
                    action.workflowId = _.castArray(action.workflowId)
                        .map(workflowId => (-1 === workflowId.indexOf('/') ? 'tourism/' : '') + workflowId);
                    if (1 === action.workflowId.length) {
                        action.workflowId = action.workflowId[0];
                    }
                }
            }

            newMenu.push(toolbar);
        }

        this.menu = newMenu;

        logger.debug('Cart view toolbars were initialized', menu);
    }

    attached() {
        this.subscriptions = [
            this.ea.subscribe('sio_form_post_submit', payload => {
                //Do not trigger reload by submitting product-choice form, we trigger it after workflow manually
                //Todo use event to reload order and trigger it at right time (after workflow execution)
                if (payload.config.id == 'order-item-add' || payload.config.modelId == 'tourism/flight' || payload.config.modelId == 'tourism-flight/itinerary') {
                    return;
                }
                if (/^(tourism)/.test(payload.config.modelId)) {
                    this.reloadOrder();
                }
            })
        ];
        this.observeItems();
    }

    reloadOrder() {
        this.ea.publish('sio_form_post_submit', {config: {modelId: 'order/order'}});
    }

    observeItems() {
        if (!this.itemsObserver) {
            this.itemsObserver = this.bindingEngine
                .collectionObserver(this.selectedItems)
                .subscribe(() => this.selectedItemsChanged());
        }
    }

    detached() {
        if (this.subscriptions) {
            this.subscriptions.forEach(subscription => subscription.dispose());
            this.subscriptions = null;
        }
        this.unobserveItems();
    }

    unobserveItems() {
        if (this.itemsObserver) {
            this.itemsObserver.dispose();
            this.itemsObserver = null;
        }
    }

    async orderChanged() {

        // avoid parallel executions
        if (this.loading) {
            return;
        }

        this.loading = true;
        this.selectedParticipants = [];
        this.selectedItems = [];
        this.allItemsChecked = false;
        this.participants = new Map();
        this.journeys = new Map();
        this.items = new Map();
        this.journeyTitle = null;
        this.withoutParticipant = Object.assign({'items': []}, this.defaultWithoutParticipant);

        this.client.get('order/cart-view/configuration/' + this.order.id).then(cartViewConfig => {
            this.initializeToolbars(cartViewConfig.toolbars);

            this.columns = this.columnsGenerator.generate(cartViewConfig);
        });

        if (!this.productColumn || !this.titleColumn) {
            let list = await this.client.get('order/order-item/list');

            this.productColumn = list.columns.find(column => 'product' === column.property);
            this.titleColumn = list.columns.find(column => 'title' === column.property);

            //Quite easy check for tourism modules atm
            this.participantsEnabled = list.embeds.indexOf('participants') > -1 && !this.order.b2bParticipants;
        }

        this.linkedOrders = await this.client.get('order/linked-order/' + this.order.id);

        let orderConditions = [];

        for (let linkedOrder of this.linkedOrders) {
            orderConditions.push('conditions[order][$in][]='+linkedOrder);
        }

        orderConditions = orderConditions.join('&');

        await this.client
            .get(
                'order/order-item?' +
                'embeds[]=product&' +
                'sort[0][0]=sort&' +
                'sort[0][1]=ASC&' +
                'conditions[order]=' + this.order.id +
                '&limit=1000'
            )
            .then(list => {
                for (const item of list.items) {
                    if (item?.order?.id == this.order?.id) {
                        item.order = this.order;
                    }
                    this.items.set(item.id, item);
                }
            });

        if (this.participantsEnabled) {

            await this.client
                .get(
                    'tourism/journey-participant?' +
                    'embeds[]=customer&limit=1500&' +
                    orderConditions
                )
                .then(list => {
                    for (const participant of list.items) {
                        participant.items = [];
                        participant.bucket = [];
                        participant.objectLabel = participant?.customer?.displayName ?? participant?.customer?.fullName ?? '(Ohne Name)';
                        this.participants.set(participant.id, participant);
                    }
                });
        }

        if (this.participantsEnabled) {
            this.buckets = await this.bucketsGenerator.buildBuckets(
                this.items,
                this.participants,
                this.withoutParticipant,
                this.journeys,
                this.defaultSortableOptions
            );
        } else {
            console.log('FROM DATE');

            this.buckets = await this.fromDateBucketsGenerator.buildBuckets(
                this.items,
                this.participants,
                this.withoutParticipant,
                this.journeys,
                this.defaultSortableOptions,
                this.order
            );
        }

        console.log('BUCKETS', this.buckets, this.items);

        const journeyTitles = [];

        let contentLocale = this.localeService.contentLocale;

        for (const journey of this.journeys.values()) {
            if (journey.tour) {
                journey.tour = await
                    this.client.get('tourism/tour/' + journey.tour.id);

                journeyTitles.push(
                    (journey?.tour?.title?.[contentLocale] ?? '') + (journey.journeyNumber ? (' / ' + journey.journeyNumber) : '') +
                    '<span class="label label-default" style="margin-left:1em">' +
                    moment(journey.startDate).format('L') + ' — ' +
                    moment(journey.endDate).format('L') + '</span>'
                );
            }
        }

        if (journeyTitles.length) {
            this.journeyTitle = journeyTitles.join('<br>');
        }

        for (let action of this.addActions) {
            action.hidden = action.hasOwnProperty('module') &&
                action.module.hasOwnProperty('if') &&
                !this.conditionMatcher.matchConditions(this.order, action.module.if);
        }

        this.loading = false;
    }

    onSortableAdd(event) {
        logger.info("onSortableAdd", event, this);

        const bucket = 'without-participant' === event.target.id ? this.withoutParticipant :
            this.buckets[event.target.id.substr(7)]
        ;

        if (!this.selectedParticipants.length) {
            for (let participant of bucket.participants) {
                this.selectedParticipants.push(participant.id);
            }
        }

        this.unobserveItems();
        this.selectedItems = [event.detail.item.id];
        this.observeItems();

        this.executeAction({
            type: 'workflow',
            workflowId: 'tourism/copy-order-items',
            move: true
        });
    }

    async onSortableUpdate(event) {
        logger.info("onSortableUpdate", event, this);

        const items = 'without-participant' === event.target.id ? this.withoutParticipant.items :
            this.buckets[event.target.id.substr(7)].items; // strip "bucket-"

        // noinspection JSIgnoredPromiseFromCall
        const newOrder = (await this.client.patch('order/order-items/' + this.order.id + '/resort', {
            item: items[event.oldIndex].id[0],
            sort: items[event.newIndex].sort
        })).data;
        const sortBucket = function (items, newOrder) {
            const sortedItems = [];

            _.forEach(newOrder, (itemId, pos) => {
                const item = items.find(item => item.id[0] === itemId);
                if (item) {
                    item.sort = pos;
                    sortedItems.push(item);
                }
            });

            return sortedItems;
        };

        this.withoutParticipant.items = sortBucket(this.withoutParticipant.items, newOrder);
        _.forEach(this.buckets, (bucket) => {
            bucket.items = sortBucket(bucket.items, newOrder);
        });

        return false;
    }

    addEmptyParticipant() {

        return this.actions.getAction({
            type: 'workflow',
            workflowId: 'tourism-order/participant-add',
            formId: 'tourism-order/participant-add'
        }, {id: this.order.id}).action();
    }

    addParticipant() {

        let orderOrganization = this.order.organization.parent ? this.order.organization.parent.id : this.order.organization.id;

        return this.dialogService
            .open({
                viewModel: UniversalEntitySelect,
                model: {
                    selectModelId: 'customer/customer',
                    title: 'Kunde als Teilnehmer hinzufügen',
                    multiSelect: true,
                    conditions: {
                        organization: {
                            $eq: {
                                id: orderOrganization,
                                modelId: 'organization/organization'
                            }
                        }
                    }
                }
            })
            .whenClosed(data => {
                const items = data.output;

                if (data.wasCancelled || !items || 0 === items.length) {
                    return Promise.resolve(null);
                }

                return this.client
                    .patch('tourism-order/participant-customer-add/trigger/' + this.order.id, {customers: items})
                    .then(() => this.reloadOrder());
            });

    }

    _getParticipants(participants) {
        return participants.map(id => {
            return this.participants.get(id);
        });
    }

    _getParticipantTitle(participants) {
        return this._getParticipants(participants).sort((a, b) => a.num- b.num).map(participant => {
            let name = participant.num + '. Teilnehmer';

            if (participant.customer) {
                return name + ': ' + participant.customer.displayName;
            } else {
                return name;
            }

        }).join('<br>');
    }

    addToOrder(module) {

        if (module.needsParticipantSelection && (0 === this.selectedParticipants.length && this.order.b2bParticipants == null)) {
            this.flash.error('Bitte erst mindestens einen Teilnehmer auswählen.');
            return;
        }

        let title = this.i18n.tr(module.label) + ' zu Buchung ' + this.order.orderNumber + ' hinzufügen<hr>';

        if (this.journeyTitle) {
            title += '<div class="pull-right text-right" style="max-width:66%;font-size:70%">' + this.journeyTitle + '</div>';
        }

        title += '<div class="pull-left" style="max-width:32%;font-size:70%">' +
            this._getParticipantTitle(this.selectedParticipants) +
            '</div><div class="clearfix"></div>';

        let promise;

        if (null == module.productSelectionModelId) {
            promise = Promise.resolve(null);
        } else {

            let viewModel = UniversalEntitySelect;
            let endpoint = null;

            if (module.productSelectionModelId == 'tourism-flight/itinerary') {
                viewModel = FlightDialog;
            } else if (module.key == 'tourism-accommodation/room') {
                viewModel = AccommodationSearch;
                endpoint = 'tourism-accommodation/search';
            } else if (module.key == 'tourism-service/service') {
                viewModel = ServiceSearch;
                endpoint = 'tourism-service/search';
            } else if (module.key == 'tourism-transfer/transfer') {
                viewModel = TransferSearch;
                endpoint = 'tourism-transfer/search';
            } else if (module.key == 'tourism-ship/ship') {
                viewModel = AccommodationSearch;
                endpoint = 'tourism-ship/search';
            }

            promise = this.dialogService
                .open({
                    viewModel: viewModel,
                    model: {
                        participants: this._getParticipants(this.selectedParticipants),
                        selectModelId: module.productSelectionModelId,
                        title: title,
                        multiSelect: false,
                        conditions: module.conditions,
                        viewContext: 'order/order',
                        order: this.order,
                        endpoint: endpoint,
                    }
                })
                .whenClosed(data => {
                    const items = data.output;

                    if (data.wasCancelled || !items || 0 === items.length) {
                        return Promise.resolve(null);
                    }

                    return Promise.resolve(items);
                });
        }

        return promise
            .then(items => {

                if (module.productSelectionModelId != null) {
                    if (!items || 0 === items.length) {
                        return null;
                    }
                }

                const data = {
                    participants: this.selectedParticipants,
                    provider: module.key
                };

                if (module.productSelectionModelId != null) {
                    data['product'] = {id: items[0].id, modelId: items[0].modelId};
                }

                let productChoice = ProductChoice;

                console.log("Module key: " , module.key);
                if (module.key === 'tourism-insurance/api') {
                    productChoice = InsuranceApiChoice;
                } else if (module.key === 'tourism-flight/fare-search') {
                    productChoice = FlightSearch;
                } else if (module.key === 'transport/ticket') {
                    productChoice = TicketSearch;
                }

                return this.formConfigLoader.get('order/order-item-add', data).then(formConfig => {
                    if (formConfig.fields.some(field => true !== field.hidden)) {
                        //Todo make configurable via core
                        return this.dialogService
                            .open({
                                viewModel: productChoice,
                                model: {data: data, order: this.order, title: title, participants: this._getParticipants(this.selectedParticipants)}
                            })
                            .whenClosed(data => {
                                return Promise.resolve(data.wasCancelled ? null : data.output);
                            });
                    } else {
                        return Promise.resolve(data);
                    }
                });

            })
            .then(data => {
                if (!data) {
                    return;
                }

                if (data.message) {
                    this.flash.error(data.message);
                    return;
                }

                return this.client
                    .patch('order/order-item-add/trigger/' + this.order.id, data)
                    .then(() => {
                        this.ea.publish('sio_form_post_submit', {config: {modelId: 'order/order'}});
                        this.flash.success('form.success');
                    });
            })
            .catch(error => {
                console.error(error);

                if (error && error.data) {
                    if (error.data.localizedMessage) {
                        error = error.data.localizedMessage;
                    } else if (error.data.message) {
                        error = error.data.message;
                    }
                }

                this.flash.error(error || 'Ein unbekannter Fehler ist aufgetreten.');
            });
    }

    selectedItemsChanged() {
        console.log('selected items changed', this.selectedItems);

        this.allItemsChecked = _.flatten(this.selectedItems).length >= this.items.size;

        if (this.selectAllCheckbox) {
            this.selectAllCheckbox.indeterminate = !this.allItemsChecked && !!this.selectedItems.length;
        }
    }

    selectAllItems() {
        this.unobserveItems();
        this.selectedItems = this.allItemsChecked ? Array.from(this.items.values()).map(i => i.id) : [];
        this.selectedParticipants = this.allItemsChecked ? Array.from(this.participants.values()).map(p => p.id) : [];
        this.observeItems();
    }

    canEditProductOptions(item) {
        let module = this.productProviders[item.provider];

        if (!module) {
            return false;
        }

        return module.supportsUpdate;
    }

    _getParticipantsAndContextIds(item) {
        let editParticipants = [];

        let ids = _.castArray(item.id);

        let contextIds = ids.filter(id => {
            const item = this.items.get(id);

            if (!item.participants || item.participants.length == 0) {
                return true;
            }

            let returnValue = false;

            for (let participant of item.participants) {
                if (this.selectedParticipants.includes(participant.id) || this.selectedParticipants.length == 0) {
                    returnValue = true;
                }
            }

            if (returnValue) {
                for (let participant of item.participants) {
                    editParticipants.push(participant.id);
                }
            }

            return returnValue;
        });

        return [
            contextIds,
            editParticipants
        ];
    }

    editProductOptions(item) {
        const [contextIds, editParticipants] = this._getParticipantsAndContextIds(item);

        console.log('EDIT PART', editParticipants, contextIds);

        let title = 'Produktoptionen bearbeiten';

        if (editParticipants.length > 0) {
            title += '<hr><div class="pull-left" style="max-width:32%;font-size:70%">' +
                this._getParticipantTitle(editParticipants) +
                '</div><div class="clearfix"></div>';
        }

        if (contextIds.length == 0) {
            return Promise.resolve();
        }

        let context = {
            id: contextIds,
            modelId: item.modelId,
            title: title,
            disableLocalStorage: true,
        };

        return this.actions.getAction({
            type: 'view',
            icon: 'pencil',
            buttonClass: 'btn btn-danger btn-xs',
            viewId: 'order/order-item-update-from-provider',
            bulk: true,
            modal: true
        }, context).action()
    }

    /**
     * Edits options on order items
     * If participant is selected it edits only the items that belong to this participant
     * @param item
     */
    editItem(item) {
        const [contextIds, editParticipants] = this._getParticipantsAndContextIds(item);

        console.log('EDIT PART', editParticipants, contextIds);

        let title = 'Leistung bearbeiten';

        if (editParticipants.length > 0) {
            title += '<hr><div class="pull-left" style="max-width:32%;font-size:70%">' +
                this._getParticipantTitle(editParticipants) +
                '</div><div class="clearfix"></div>';
        }

        if (contextIds.length == 0) {
            return Promise.resolve();
        }

        let context = {
            id: contextIds,
            modelId: item.modelId,
            title: title,
        };

        return this.actions.getAction({
            type: 'view',
            icon: 'pencil',
            buttonClass: 'btn btn-danger btn-xs',
            viewId: 'order/order-item-edit',
            bulk: true,
            modal: true
        }, context).action()
    }

    executeAction(origAction) {
        const executionContext = {
            selectedParticipants: this.selectedParticipants,
            selectedItems: this._getSelectedItems(),
            participants: this.participants,
            items: this.items,
            allItemsChecked: this.allItemsChecked,
            order: this.order,
            journeyTitle: this.journeyTitle,
        };

        const action = _.defaults({}, origAction); // avoid modifying menu action

        return this.executorRegistry
            .execute(action, executionContext)
            .then(context => context && this.actions.getAction(action, context).action())
            .then(result => this.executorRegistry.afterExecutionCallback(action, executionContext, result))
            .then(data => {
                if (action.hasOwnProperty('downloadProperty')) {
                    const url = _.get(data, action.downloadProperty);

                    if (url) {
                        window.location.href = url;
                        return;
                    }
                } else if (action.hasOwnProperty('printProperty')) {

                    const url = _.get(data, action.printProperty);

                    //We can only download file using printjs if the CORS headers are set correct (which is only the case if file gets downloaded through CDN)
                    //Which is only configured on production server atm
                    if (url) {
                        printJS({printable: url, type: 'pdf'});
                    }
                }
                return this.reloadOrder();
            })
            .catch(error => {
                console.error(error);

                if (error && error.data) {
                    if (error.data.localizedMessage) {
                        error = error.data.localizedMessage;
                    } else if (error.data.message) {
                        error = error.data.message;
                    }
                }

                this.flash.error(error || 'Ein unbekannter Fehler ist aufgetreten.');
                return this.reloadOrder();
            });
    }

    collapseBucket(bucketKey, collapsed) {
        const bucket = this.buckets.find(bucket => bucket.bucketKey === bucketKey);

        bucket.collapsed = collapsed;
    }

    selectParticipant(id) {
        const bucket = this.buckets.find(bucket => bucket.participants.some(participant => participant.id === id));
        const selected = this.selectedParticipants.includes(id);

        if (!selected) {
            // uncheck all bucket items if only connected to unchecked participant
            for (let participant of bucket.participants) {
                if (this.selectedParticipants.includes(participant.id)) {
                    return; // at least some other participant in this bucket is checked
                }
            }
        }

        const ids = bucket.items.map(item => item.id);

        if (selected) {
            for (id of ids) {
                if (!this.selectedItems.includes(id)) {
                    this.selectedItems.push(id);
                }
            }
        } else {
            this.unobserveItems();
            this.selectedItems = this.selectedItems.filter(id => !ids.includes(id));
            this.observeItems();
        }
    }

    _getSelectedItems() {

        console.log('SELECTED ITEMS', this.selectedItems);

        //Remove double items here
        const ids = _.uniq(_.flatten(this.selectedItems));

        if (!ids.length || !this.selectedParticipants.length || this.participants.size === this.selectedParticipants.length) {
            return ids;
        }

        return ids.filter(id => {
            const item = this.items.get(id);

            if (!item.participants || item.participants.length == 0) {
                return true;
            }

            for (let participant of item.participants) {
                if (this.selectedParticipants.includes(participant.id)) {
                    return true;
                }
            }

            return false;
        });
    }

    stringifyWarnings(participantId) {
        if (!this.order || !this.order.warnings || !this.order.warnings[participantId]) {
            return '';
        }

        let warnings = this.order.warnings[participantId];
        warnings = warnings.map(
            warning => "<div> - " + warning + "</div>"
        );

        return warnings.join("<br />");
    }

    async openPurchasePrice(id) {
        await this.dialogService.open({
            viewModel: CostsDialog,
            model: {
                destination: {id, modelId: "order/order-item"}
            }
        }).whenClosed();
    }

    async openChargesContent(item)
    {
        await this.dialogService.open({
            viewModel: ChargesDialog,
            model: {
                item: item
            }
        }).whenClosed();
    }

    async openCalculatedPurchasePrice(id) {
        const calculatedCostsEntries = this.items.get(id)?.calculatedCostsEntries;

        if (calculatedCostsEntries) {
            await this.dialogService.open({viewModel: CostsDialog, model: {calculatedCostsEntries}}).whenClosed();
        } else {
            this.flash.flash("info", "Keine detaillierte Auflistung verfügbar.");
        }
    }
}
